<template>
  <div class="my-news">
    <h3>我的消息</h3>
    <div class="dispose">
      <p class="read">
        <span>已读 <em>10</em></span>
        <span>未读 <em>12</em></span>
        <span>当前页标为已读</span>
        <span>全部标为已读</span>
      </p>
      <!-- 消息通知 -->
      <ul>
        <!-- 被举报通知 -->
        <li>
          <h5>举报结果通知</h5>
          <p>您举报的言论“去你大爷的”已被处理，处理结果：由于该用户第一次发表不雅言论，做限制2小时发言处理。</p>
        </li>
        <!-- 看没看 -->
        <li class="read"><label>已读</label></li>
        <!-- 限制发表言论通知 -->
        <li>
          <h5>限制发表言论通知</h5>
          <p>
            <span>您在 </span>
            <em>Vue的生命周期有哪些？</em>
            <span>中发表的言论“去你大爷的”属于“不雅言论”，已被多名用户举报。举报处理结果：限制发言两小时。</span>
          </p>
        </li>
        <!-- 看没看 -->
        <li class="read"><label>未读</label></li>
        <!-- 有人回了评论 -->
        <li>
          <h5>您有一条新的消息</h5>
          <p><em>「奔跑的蜗牛」</em>在 <em>Vue的生命周期有哪些?</em>回复了您。</p>
          <div class="comments">
            <p>我觉得你说的对</p>
            <p>我觉得你也说的对</p>
          </div>
          <label>去讨论区回复他</label>
        </li>
        <!-- 看没看 -->
        <li class="read"><label>已读</label></li>
        <!-- 欢迎入驻绿森林 -->
        <li>
          <h5>欢迎入驻绿森林</h5>
          <p>您已使用ADJUST RD账号成功注册绿森林，绿森林是一个帮助您进行技术学习的平台，通过刷题和阅读面试问答来提升自己对知识点的理解。上班摸鱼，可以去题目讨论区闲聊哦～</p>
        </li>
        <!-- 看没看 -->
        <li class="read"><label>已读</label></li>
      </ul>
      <!-- 分页  -->
      <Pagination/>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.my-news{
  padding: 0 20px;
  box-sizing: border-box;
  background-color: $background-color;
  box-shadow: $background-shadow;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  //头部
  h3{
    flex-shrink: 0;
    border-bottom: 1px solid #d8d8d8;
    margin: 0;
    padding: 15px 10px 5px 10px;
    font-weight: normal;
    font-size: 16px;
    color: $font-color-third;
  }
  .dispose{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .read{
      flex-shrink: 0;
      margin: 15px 0;
      display: flex;
      span{
        font-size: 12px;
        flex-shrink: 0;
        color: $font-color-third;
        margin-right: 20px;
        &:first-of-type{
          margin-left: 10px;
        }
        em{
          font-style: normal;
          color: $font-color;
        }
        &:nth-of-type(3){
          flex-grow: 1;
          text-align: right;
        }
      }
    }
    //消息通知
    ul{
      flex-grow: 1;
      width: 100%;
      margin: 0;
      padding: 0 120px 0 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      //消息内容
      li{
        background-color: $background-color-second;
        border-radius: 25px;
        font-size: 12px;
        padding: 10px 0 10px 20px;
        h5{
          margin: 0 0 6px 0;
        }
        p{
          margin: 0;
          em{
            font-style: normal;
            font-weight: bolder;
          }
        }
        &:first-of-type{
          width: 575px;
        }
        //评论消息
        &:nth-of-type(5){
          width: 310px;
          div{
            background-color: $background-color;
            width: 280px;
            height: 60px;
            margin: 10px 0;
            padding: 10px;
            box-sizing: border-box;
            p{
              font-size: 12px;
              margin-left: 9px;
              &:first-of-type{
                margin-left: 0;
                width: 200px;
                height: 25px;
                background-color: $background-color-second;
                border-radius: 30px;
                line-height: 25px;
                padding-left: 10px;
                box-sizing: border-box;
                margin-bottom: 5px;
              }
            }
          }
          label{
            color: $primary-color;
            margin-left: 18px;
          }
        }
      }
      //已读和未读
      .read{
        background-color: #ffffff;
        margin: 0 0 15px 0;
        label{
          color: $font-color-third;
        }
        &:nth-of-type(4){
          label{
            color: $font-color;
          }
        }
      }
    }
    .el-pagination{
      flex-shrink: 0;
    }
  }
}
</style>
